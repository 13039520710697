import React, { useEffect, useState } from 'react';
import { List, Button, Row, Col, message } from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { ShareAltOutlined, MessageFilled } from '@ant-design/icons';

export const MyFilefeedback = () => {
    const [myFileData, setMyFileData] = useState([]);
    const [shareLoading, setShareLoading] = useState(true);
    const [btnId, setBtnId] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [pageMaxSize, setPageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);

    useEffect(() => { 
        DuckIO.doGet(AppConfig.MY_FILE_FEEDBACK(1, pageMaxSize), null, (result) => {
            if (!Array.isArray(result.response.items)) {
                alert('請洽管理員!')
            } else {
                setMyFileData(result.response);
                setShareLoading(false);
            };
            
        });
    }, []);

    return (
        <Row justify="center">
            <Col xs={24}>
                <div className='title_pb20'><ShareAltOutlined />分享教材紀錄</div>
                <List
                    className='content_box'
                    locale={{ emptyText:'目前尚未分享教材'}}
                    loading={shareLoading}
                    dataSource={myFileData.items} // 教材回饋紀錄
                    pagination={{
                        onChange: (page, pageSize) => {
                                setPageSize(pageSize);
                                //get我上傳的教材API List
                                DuckIO.doGet(AppConfig.MY_FILE_FEEDBACK(page, pageSize), null, (result) => {
                                    setMyFileData(!!result.response ? result.response : []);
                                });
                        },
                        showSizeChanger: true,
                        current: myFileData.page,
                        pageSize: pageMaxSize,
                        total: myFileData.totalRecords,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <a title="上一頁" aria-label="上一頁">＜</a>;
                            }
                            if (type === 'next') {
                                return <a title="下一頁" aria-label="下一頁">＞</a>;
                            }
                            return originalElement;
                        },
                    }}
                    renderItem={x => (
                        <List.Item key={x.id}>
                            <List.Item.Meta
                                title={x.title}
                                description={<>
                                    <MessageFilled  /> {x.message}
                                    <div>{x.createAt}</div>
                                    </>}
                            />
                            {/* <List.Item.Meta
                                title={<><Tag color='geekblue'>{x.title}</Tag>{x.message}</>}
                                description={x.createAt}
                            /> */}
                            <>
                            <Button style={{margin:'1px 5px'}} type="primary" 
                                loading={btnId===x.id} 
                                disabled={btnId !== x.id && btnLoading} 
                                onClick={() => {
                                    setBtnId(x.id);
                                    setBtnLoading(true)
                                    DuckIO.download(DuckIO.path(AppConfig.REPO_DOWNLOAD_ARCHIVE(x.id)), `${x.id}.zip`, () => {
                                        message.success('下載成功');
                                        setBtnId('');
                                        setBtnLoading(false)
                                    });
                                }}> {btnId===x.id ? '下載中' :'下載檔案'}
                            </Button>
                            <Button style={{margin:'1px 5px'}} type="default" onClick={(event) => {
                                    event.stopPropagation();
                                    window.open(`/repo/${x.parentRepoId}`);
                                }}>查看此教材
                            </Button>
                            </>                                      
                        </List.Item> 
                    )}
                />
            </Col>
        </Row>        
    );

}
