import React, { useEffect, useState } from 'react';
import { List, Button, Row, Col } from 'antd';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';
import { MessageOutlined, MessageFilled  } from '@ant-design/icons';

export const MyMsg = () => {
    const [myMsgData, setMyMsgData] = useState([]);
    const [shareLoading, setShareLoading] = useState(true);
    const [pageMaxSize, setPageSize] = useState(AppConfig.DEFAULT_MAX_PAGE_SIZE);

    useEffect(() => { 
        DuckIO.doGet(AppConfig.MY_MSG_FEEDBACK(1,pageMaxSize), null, (result) => {
            if (!Array.isArray(result.response.items)) {
                alert('請洽管理員!')
            } else {
                setMyMsgData(result.response);
                setShareLoading(false)
            };
        });
    }, []);

    return(
        <Row justify="center">
            <Col xs={24}>
                <div className='title_pb20'><MessageOutlined />留言紀錄</div>
                <List
                    className='content_box'
                    locale={{ emptyText:'目前無留言紀錄'}}
                    loading={shareLoading}
                    dataSource={myMsgData.items} // 留言紀錄
                    pagination={{
                        onChange: (page, pageSize) => {
                                setPageSize(pageSize);
                                //get我上傳的教材API List
                                DuckIO.doGet(AppConfig.MY_MSG_FEEDBACK(page,pageSize), null, (result) => {
                                    setMyMsgData(!!result.response ? result.response : []);
                                });
                        },
                        showSizeChanger: true,
                        current: myMsgData.page,
                        pageSize: pageMaxSize,
                        total: myMsgData.totalRecords,
                        itemRender: (current, type, originalElement) => {
                            if (type === 'prev') {
                                return <a title="上一頁" aria-label="上一頁">＜</a>;
                            }
                            if (type === 'next') {
                                return <a title="下一頁" aria-label="下一頁">＞</a>;
                            }
                            return originalElement;
                        },
                    }}
                    renderItem={x => (
                        <List.Item key={x.id}>
                            <List.Item.Meta
                                title={x.repoName}
                                description={<><MessageFilled  /> {x.message}</>}
                            />
                            <div>
                                <Button type="default" onClick={(event) => {
                                         event.stopPropagation();
                                         window.open(`/repo/${x.id}`);
                                     }} aria-label={`查看教材 ${x.repoName}`}>
                                     查看此教材
                                 </Button> 
                            </div>
                        </List.Item>
                    )}
                />
            </Col>
        </Row>

    );
};
