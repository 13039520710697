import React, { useEffect, useState } from 'react';
import { Button, Modal, Form, Input, Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DuckIO from '../ducks/DuckIO';
import AppConfig from '../config/AppConfig';

export const FeedbackModal = ({ feedBackModalType, setFeedBackModalType, repoData, msgFbLoading, fileFbLoading, setMsgFbLoading,setFileFbLoading }) => {
    const [form] = Form.useForm();
    const [submitBtnLoading, setSubmitBtnLoading] = useState(false);  // modal 儲存按鈕loading完 再將modal關閉
    console.log(feedBackModalType)
    const handleCancel = () => {
        form.resetFields();
        setFeedBackModalType(null);
    }

    const handleOk = () => {
        form.submit();  //會呼叫form 函式 onFinish實作表單
    };

    const onFinish = (values) => {
        //呼叫 儲存的API
        console.log("values",values)
        
        if (feedBackModalType[0] === 'msg') {
          setSubmitBtnLoading(true);
          let data = {
            repoId : repoData.id,
            message: values.message,
            commitVer: feedBackModalType[1]
            
          };
          DuckIO.doPost(AppConfig.POST_REPLY_FEEDBACK(), data, null, (result) => {
              setSubmitBtnLoading(false);
              setMsgFbLoading(!msgFbLoading);
              setFeedBackModalType(null);
          });
        } else {
          setSubmitBtnLoading(true);
          let data = {
            repoId : repoData.id,
            message: values.message,
            upload: values.upload,
            commitVer: feedBackModalType[1]
          };
          DuckIO.doPost(AppConfig.POST_FILE_FEEDBACK(), data, null, (result) => {
              setSubmitBtnLoading(false);
              setFileFbLoading(!fileFbLoading);
              setFeedBackModalType(null);
          });
        }
      };
    
    const onFinishFailed = (errorInfo) => {
        const err = errorInfo.errorFields.map(x=>{
            return x.errors
        });
        alert(err.join('\n'))
        console.log('Failed:', errorInfo);
    };

    const normFile = (e) => {
        setSubmitBtnLoading(true);

        if (Array.isArray(e)) {
          return e;
        }

        if (e.file.status !== 'uploading') {
          setSubmitBtnLoading(false)
        }
        return e?.fileList;
      };
    
    
      return (
        <>
          <Modal
            open={!!feedBackModalType}
            title={feedBackModalType[0] === 'msg' ? `給【${repoData.title}】留言或建議` : `給【${repoData.title}】分享教材`}
            closable={false}
            maskClosable={!submitBtnLoading}
            onCancel={handleCancel}
            aria-labelledby="留言或建議視窗"
            footer={[
              <Button key="back" onClick={handleCancel} disabled={submitBtnLoading} aria-label="取消">
                取消
              </Button>,
              <Button key="submit" type="primary" loading={submitBtnLoading} onClick={handleOk} aria-label="送出">
                {submitBtnLoading ? '送出中' : '送出'}
              </Button>
            ]}
          >
            <Form
                form={form}
                disabled={submitBtnLoading}
                onFinish={onFinish}  // 驗證通過呼叫
                onFinishFailed={onFinishFailed} // 驗證不通過呼叫
                aria-labelledby="feedback-form"
                //autoComplete="off"
            >
                <Form.Item label={"留言或建議"} name="message" rules={[{ required: true, message: '請輸入留言或建議' }]} >
                    <Input />
                </Form.Item>
                {
                  feedBackModalType[0] === 'file' &&
                  <Form.Item
                    name="upload"
                    label="分享教材"
                    method={"post"}  //預設post
                    valuePropName="fileList"
                    getValueFromEvent={normFile} // 每選擇一次教材會觸發一次
                    multiple={true} //可多選文件
                    onChange={(a)=>{console.log(a)}}
                    rules={[{ required: true, message: '請上傳教材檔案' }]}
                >
                    <Upload
                        name="file"
                        action={DuckIO.path(AppConfig.REPO_WEB_UPLOAD())} //放進redis
                        listType="text" //上傳文件類型
                        data={file => ({
                            uid: file.uid
                        })}
                        beforeUpload={(e)=>{
                            console.log(e)
                        }}
                    >
                        <Button icon={<UploadOutlined />}>選擇檔案</Button>
                        <span className='memo'>(支援上傳多個檔案)</span>
                    </Upload>
                </Form.Item>
                }
                
            </Form>
          </Modal>
        </>
      )
}
