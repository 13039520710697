import React, { useState } from 'react';
import AppConfig from '../config/AppConfig';
import { AllList } from './allList';
import { Menu } from 'antd';
import { StarFilled, ProfileFilled } from '@ant-design/icons';

export const DaleRepo = () => {
    const [menu, setMenu] = useState('1');

    const handleClick = (e) => {
        setMenu(e.key);
    };

    const menuItem = [
        {
            label: '教材列表',
            key: '1',
            icon: <ProfileFilled />,
        },
        {
            label: '熱門教材',
            key: '2',
            icon: <StarFilled  />,
        },
    ]

    return (
        <div>
            <div>
                <Menu onClick={handleClick} items={menuItem} selectedKeys={[menu]} mode="horizontal" aria-label="教材選單">
                    {/* <Menu.Item key="1">教材列表</Menu.Item>
                    <Menu.Item key="2">熱門教材</Menu.Item> */}
                    {/* {false && !!AppConfig.MEMBER && <Menu.Item key="3">相關領域教材</Menu.Item>} */}
                    {/* {!!AppConfig.MEMBER && <Menu.Item key="4">我的教材</Menu.Item>} */}
                </Menu>
            </div>
            <div>
                <AllList  menu={menu} />
            </div>
        </div>
    );
};
