import './App.css';
import { Route, Routes, Link, useNavigate, Navigate, useLocation, NavLink} from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { DaleRepo } from './repo/index';
import { Profile } from './profile/index';
import { MyConfig } from './myConfig/index';
import { File } from './repo/file';
import { Button, Row, Col, FloatButton, Avatar, Badge, Space, Dropdown } from 'antd';
import { Detail } from './repo/detail';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import DuckIO from './ducks/DuckIO';
import AppConfig from './config/AppConfig';
import { LogoutOutlined, LoginOutlined, UserOutlined, NotificationFilled, ProfileFilled, BulbOutlined } from '@ant-design/icons';

const defaultName = '目前身分為【訪客】';

const AuthContext = React.createContext(null);

const Page404 = () => {
    return (
        <div style={{ textAlign: 'center' }}>
            <Row alig="middle" justify="center">
                <Col span={12} className="common_text">
                    查無此頁面
                </Col>
            </Row>
            <Row alig="middle" justify="center">
                <Col span={12}>
                    <img className="img_wh" alt='查無此頁面' src={'images/404.png'} ></img>
                </Col>
            </Row>
            <Row alig="middle" justify="center">
                <Col span={12}>
                    <NavLink to="/">
                        <Button block type="primary" size={"large"} aria-label="回首頁">
                            回首頁
                        </Button>
                    </NavLink>
                </Col>
            </Row>
        </div>
    );
};

const PageNoPermission = () => {
    const { onLogin } = React.useContext(AuthContext);

    return (
        <div style={{ textAlign: 'center' }}>
            <Row alig="middle" justify="center">
                <Col span={12} className="common_text">
                    無查看權限
                </Col>
            </Row>
            <Row alig="middle" justify="center">
                <Col span={12}>
                    <img className="img_wh" alt="無查看權限" src={'images/nopermission.png'}></img>
                </Col>
            </Row>
            <Row alig="middle" justify="center">
                <Col span={12}>
                    <Button 
                        type="primary" 
                        block 
                        icon={<LoginOutlined />} 
                        onClick={() => onLogin()} 
                        aria-label="使用 Google 登入"
                    >
                        Google登入
                    </Button>
                </Col>
            </Row>
        </div>
    );
};

const AuthProvider = ({ children }) => {
    const [name, setName] = useState(defaultName);
    const [token, setToken] = useState(null);
    const navigate = useNavigate();
    const location = useLocation();

    const login = useGoogleLogin({
        onSuccess: (codeResponse) => {
            DuckIO.doFormdata(
                AppConfig.SIGNIN_MEMBER(),
                {
                    accessToken: codeResponse.access_token,
                    loginMethod: 'Google',
                },
                (resp) => {
                    doLogin(resp, setName, setToken, navigate, location)
                },
            );
        },
        onError: (error) => console.log('Login Failed:', error),
    });

    const logOut = () => {
        googleLogout();
        setToken(null);
        setName(defaultName);
        navigate('/'); // 回首頁
        DuckIO.doPost(AppConfig.SIGNOUT_MEMBER());
        AppConfig.MEMBER = null
    };

    const value = {
        token,
        name,
        setName,
        setToken,
        onLogin: login,
        onLogout: logOut,
    };

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
    return React.useContext(AuthContext);
};

const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    const location = useLocation();

    if (!token) {
        // 若該頁無權限 則導至無權限頁
        return <Navigate to="/permission" replace state={{ from: location }} />;
    }

    return children;
};

const getHisRoute = (location) => {

    if (location.state) {
        return location.state.from.pathname;
    } else if (location) {
        return location.pathname;
    } else {
        return '/'
    }
}

var autoSelect = false;
function doLogin(resp, setName, setToken, navigate, location) {
    if(resp.resultCode == 10) {
        // check gitlab info
        DuckIO.doPost(AppConfig.REPO_INFO());
        AppConfig.MEMBER = resp.response;
        setToken(resp.response);
        setName(`目前登入者為【 ${AppConfig.MEMBER.name}】`);

        // 無權限 登入後 跳回原本所在頁
        let origin = getHisRoute(location);
        navigate(origin);
    }
}

const Nav = () => {
    const { onLogin, onLogout, name, setName, token, setToken } = React.useContext(AuthContext);

    const navigate = useNavigate();
    const location = useLocation();
    const [unreadMsg, setUnreadMsg] = useState(0);
    const [msg, setMsg] = useState([]);

    useEffect(() => {
        if(!autoSelect) {

            DuckIO.doGet(
                AppConfig.SIGNED_MEMBER(), null,
                (resp) => {
                    doLogin(resp, setName, setToken, navigate, location);
                },
            );

            DuckIO.doGet(AppConfig.MESSAGING_UNREAD(), null,(result) => {
                    setUnreadMsg(result.response);
                },
            );

            DuckIO.doGet(AppConfig.MESSAGING_GROUP(), null,(result) => {
                if(result.resultCode !== 10) {
                    setMsg([]);
                } else {
                    let a = result.response.items.map((x,i)=> {
                    //console.log(result.response.items.length)
                        return {
                            label: x.content,
                            key:i,
                            icon: <BulbOutlined style={{color:'#FFD000',fontSize:'16px'}}/>
                        }}
                    );
                    setMsg(a);
                }
                
            },
        );

            

            autoSelect = true
        }
    },[]);

    return (
        <div className="top">
            <Row justify="space-between" align="middle">
                <Col sm={{ span: 18 }} xs={{ span: 24 }}>
                    <Link to={'/'}>
                        <h1 className="top_name">有愛無礙Dale教材資源庫</h1>
                    </Link>
                </Col>

                <Col sm={{ span: 6 }} xs={{ span: 24 }}>
                    <div style={{textAlign: "right"}}>
                        {token ? (
                            <Space align="baseline" size={'large'}>
                                <Link to={'/config'}>
                                    <Badge>
                                        <Avatar icon={<UserOutlined />} aria-label="個人設定" />
                                    </Badge>
                                </Link>
                                <Link to={'/profile'}>
                                    <Badge>
                                        <Avatar icon={<ProfileFilled />} aria-label="個人檔案" />
                                    </Badge>
                                    
                                </Link>

                                <div>
                                    <Dropdown
                                        menu={{ items: msg }}
                                        placement="bottomRight"
                                        aria-haspopup="true"
                                        aria-expanded={msg.length > 0}
                                    >
                                        <Badge count={unreadMsg} showZero color="#DC3A04">
                                            <Avatar style={{ cursor: 'pointer' }} icon={<NotificationFilled />} aria-label="通知" />
                                        </Badge>
                                    </Dropdown>
                                </div>
                                
                                
                                <Badge onClick={() => {onLogout()}} title="登出">
                                    <Avatar style={{cursor:'pointer'}} icon={<LogoutOutlined />} aria-label="登出" />
                                </Badge>   
                            </Space>
                        ) : (
                            <Badge onClick={() => {onLogin()}}>
                                <Avatar style={{cursor:'pointer'}} shape='square' aria-label="登入">
                                    登入
                                </Avatar>
                            </Badge>
                        )}
                    </div>
                </Col>
            </Row>
            <Row justify="end" align="middle">
                <Col className="top_user" aria-label={`目前登入者：${name}`}>{name}</Col>
            </Row>
        </div>
    );
};

function App() {
    const [scroll, setScroll] = useState(false);

    const handleScroll = () => {
        const currentScrollY = window.scrollY;

        if (currentScrollY > 0) {
            setScroll(true);
        } else {
            setScroll(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [scroll]);

    return (
        <div className="container">
            <div className="main">
                <AuthProvider>
                    <Nav aria-label="主選單" />
                    <Routes>
                        <Route index path="/" element={<DaleRepo />} exact />
                        <Route path="/repo/:id" element={<Detail />} />
                        <Route
                            path="/profile"
                            element={
                                <ProtectedRoute>
                                    <Profile />
                                </ProtectedRoute>
                            }
                            exact
                        />
                        <Route
                            path="/config"
                            element={
                                <ProtectedRoute>
                                    <MyConfig />
                                </ProtectedRoute>
                            }
                            exact
                        />
                        <Route path="/file/:id" element={<File />} exact />
                        <Route path="/permission" element={<PageNoPermission />} exact />
                        <Route path="*" element={<Page404 />} />
                    </Routes>
                </AuthProvider>
                <FloatButton.BackTop />
            </div>

            <div className="bottom">
                <Row align="middle" justify="center" style={{ height: '80px' }}>
                    <Col span={24} flex={1}>
                        Copyright © 2023 Dale. All rights reserved.
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default App;
